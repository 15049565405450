<template>
	<div id="app">
		<AppMenu>
			<FilterMessage />
			<AppSearch />
			<FilterBase type="side" />
		</AppMenu>

		<div class="page">
			<AppHeader />

			<main v-if="loaded && getAnimalsByErathem().length">
				<SectionTimeline />
				<SectionOverview />
			</main>
			<BaseLoading v-else>
				<template v-if="hasFilters">
					<p>Hier gibt es nichts zu sehen!</p>
					<FilterButtonReset />
				</template>
			</BaseLoading>

			<SectionSuggestions />

			<AppFooter />
		</div>

		<AppModal />
	</div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';


export default {
	name: 'App',
	components: {
		AppFooter: () => import(/* webpackChunkName: "AppFooter" */ '@/components/AppFooter'),
		AppHeader: () => import(/* webpackChunkName: "AppHeader" */ '@/components/AppHeader'),
		AppMenu: () => import(/* webpackChunkName: "AppMenu" */ '@/components/AppMenu'),
		AppModal: () => import(/* webpackChunkName: "AppModal" */ '@/components/AppModal'),
		AppSearch: () => import(/* webpackChunkName: "AppSearch" */ '@/components/AppSearch'),
		FilterBase: () => import(/* webpackChunkName: "FilterBase" */ '@/components/filter/FilterBase'),
		FilterMessage: () => import(/* webpackChunkName: "FilterMessage" */ '@/components/filter/FilterMessage'),
		SectionOverview: () => import(/* webpackChunkName: "SectionOverview" */ '@/components/SectionOverview'),
		SectionSuggestions: () => import(/* webpackChunkName: "SectionSuggestions" */ '@/components/SectionSuggestions'),
		SectionTimeline:() => import(/* webpackChunkName: "SectionTimeline" */ '@/components/SectionTimeline')
	},
	computed: {
		...mapGetters(['getAnimalsByErathem', 'hasFilters']),
		...mapState(['loaded'])
	},
	created() {
		this.$store.dispatch('getAll');
	}
};
</script>

<style lang="scss">
	@import 'scss/layout';
</style>
