import Vue from 'vue';
import {store} from './store/';
import './registerServiceWorker';

// Global Components
import App from './App';
import BaseLoading from './components/base/BaseLoading';

Vue.component('BaseButton', () => import(/* webpackChunkName: "BaseButton" */ '@/components/base/BaseButton'));
Vue.component('BaseButtonClose', () => import(/* webpackChunkName: "BaseButtonClose" */ '@/components/base/BaseButtonClose'));
Vue.component('BaseDropdown', () => import(/* webpackChunkName: "BaseDropdown" */ '@/components/base/BaseDropdown'));
Vue.component('BaseExternalLink', () => import(/* webpackChunkName: "BaseExternalLink" */ '@/components/base/BaseExternalLink'));
Vue.component('BaseFieldValue', () => import(/* webpackChunkName: "BaseFieldValue" */ '@/components/base/BaseFieldValue'));
Vue.component('BaseIcon', () => import(/* webpackChunkName: "BaseIcon" */ '@/components/base/BaseIcon'));
Vue.component('BaseOverlay', () => import(/* webpackChunkName: "BaseOverlay" */ '@/components/base/BaseOverlay'));
Vue.component('BasePaginationProgress', () => import(/* webpackChunkName: "BasePaginationProgress" */ '@/components/base/BasePaginationProgress'));
Vue.component('BaseText', () => import(/* webpackChunkName: "BaseText" */ '@/components/base/BaseText'));
Vue.component('FilterButtonReset', () => import(/* webpackChunkName: "FilterButtonReset" */ '@/components/filter/FilterButtonReset'));
Vue.component('BaseLoading', BaseLoading);

new Vue({
	store,
	el: '#app',
	render: h => h(App)
});
