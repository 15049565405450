<template>
	<div class="loading">
		<div class="loading-inner">
			<svg
				class="loading-icon"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 512 512"
			>
				<use :xlink:href="id" />
			</svg>
			<slot />
		</div>
	</div>
</template>

<script>
const
	icons = [
		'stegosaurus',
		'tyrannosaurus',
		'brachiosaurus',
		'velociraptor',
		'spinosaurus'
	]
;

export default {
	props: {
		message: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			id: null,
			icons: [],
			timer: null
		};
	},
	created: function() {
		icons.forEach((el) => {
			this.icons.push(`#${require(`scharfzahn-svg/animals/${el}.svg?sprite`).default.id}`);
		});

		let i = 0;

		this.timer = window.setInterval(() => {
			if (++i >= this.icons.length) {
				i = 0;
			}

			this.id = this.icons[i];
		}, 1000);
	},
	destroyed: function() {
		window.clearInterval(this.timer);
	}
};
</script>

<style lang="scss">
	.loading {
		position: relative;
		height: 100vh;
		background: radial-gradient(circle at center, $color-light 199px, rgba(0, 0, 0, 0) 200px);

		&-inner {
			transform: translate(-50%, -50%);
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			max-width: 400px;
			color: $color-text;
			font-size: 0.875rem;
			font-weight: bold;
			text-align: center;
		}

		&-icon {
			animation: loading 1s infinite ease-out;
			display: block;
			opacity: 0;
			transform: scale(1);
			margin: 1rem auto;
			width: 125px;
			height: 125px;
			fill: $color-brand;
		}
	}

	@keyframes loading {
		50% {
			opacity: 1;
		}

		to {
			opacity: 0;
			transform: scale(1.1);
		}
	}
</style>
