import Vue from 'vue';
import Vuex from 'vuex';
import {filter, has} from '@schascha/brabbelback';
import {settings} from '../settings';
import {filterStore} from './modules/filter';
import {wikipedia} from './modules/wikipedia';


Vue.use(Vuex);


async function __fetch(id, commit, mutation) {
	const
		response = await fetch(settings.endpoint.api + id),
		data = await response.json()
	;

	return commit(mutation, data);
}


export const store = new Vuex.Store({
    modules: {
		filterStore,
		wikipedia
	},
	state: {
		animals: [],
		diet: '',
		diets: [],
		erathem: {},
		erathems: [],
		loaded: false,
		allMedia: [],
		media: '',
		periods: [],
		period: {},
		search: '',
		taxa: [],
		taxon: ''
	},
	mutations: {
		setAnimals(state, data = []) {
			state.animals = data;
		},
		setDiet(state, diet = '') {
			state.diet = diet;
		},
		setDiets(state, diets = []) {
			state.diets = diets;
		},
		setErathem(state, erathem) {
			state.erathem = erathem || state.erathems[1];
		},
		setErathems(state, erathems = []) {
			state.erathems = erathems;
			state.erathem = erathems[1];  // Default
		},
		setLoaded(state, loaded = false) {
			state.loaded = loaded;
		},
		setAllMedia(state, data = []) {
			state.allMedia = data;
		},
		setMedia(state, data = '') {
			state.media = data;
		},
		setPeriods(state, periods = []) {
			state.periods = periods;
		},
		setPeriod(state, period = {}) {
			state.period = period;

			// Set erathem depending on period
			if (period && period.name) {
				state.erathem = state.erathems.find(({periods}) => has(periods, period.name));
			}
		},
		setSearch(state, data) {
			state.search = data;
		},
		setTaxa(state, taxa = []) {
			state.taxa = taxa;
		},
		setTaxon(state, taxon = '') {
			state.taxon = taxon;
		},
		sortAnimals(state, type) {
			switch (type) {
				case 'name':
					state.animals.sort((a, b) => {
						return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
					});
					break;
				case 'start':
					state.animals.sort((a, b) => {
						return b.start - a.start;
					});
					break;
				case 'size':
					state.animals.sort((a, b) => {
						const size = ({height, length}) => (height || 1) * (length || 1);
						return size(b) - size(a);
					});
					break;
				case 'year':
					state.animals.sort((a, b) => {
						const year = ({species_description: str}) => (str) ? str.replace(/^[^\d]*(\d{4}).*$/, '$1') : 9999;
						return year(a) - year(b);
					});
					break;
			}
		}
	},
	getters: {
		animals(state) {
			return filter(state.animals, state.filterStore.filters);
		},
		getActiveErathem(state, getters) {
			const period = getters.animals[0].period;
			return state.erathems.find(({periods}) => has(periods, period));
		},
		getAnimalsByErathem: (state, getters) => (erathem = state.erathem) => {
			const
				{periods} = erathem,
				animals = getters.animals.filter((el) => has(periods, el.period))
			;
			return animals;
		},
		relatedAnimals: (state) => (item) => {
			return state.animals.filter(({end, period, slug, start}) => {
				return slug !== item.slug && start >= item.end && end <= item.start && has(period, item.period);
			});
		},
		getDiet: (state) => (name) => {
			return name && state.diets.find((el) => el.name === name);
		},
		getPeriodByName: (state) => (name) => {
			return name && state.periods.find((el) => el.name === name);
		},
		getTaxon: (state) => (name) => {
			const
				obj = {},
				children = (el, parent) => {
					obj.parent = parent;

					if (el.name === name) {
						obj.current = el;
						return true;
					}

					if (el.children) {
						return el.children.find((child) => children(child, el));
					}
			};

			state.taxa.find((el) => children(el));

			return obj;
		}
	},
	actions: {
		getAll({commit, dispatch}) {
			return Promise.all([
				dispatch('getAnimals'),
				dispatch('getDiets'),
				dispatch('getErathems'),
				dispatch('getAllMedia'),
				dispatch('getPeriods'),
				dispatch('getTaxa')
			]).finally(() => {
				commit('setLoaded', true);
			});
		},
		async getAnimals({commit}) {
			return await __fetch('animals', commit, 'setAnimals');
		},
		async getDiets({commit}) {
			return await __fetch('diets', commit, 'setDiets');
		},
		getErathems({commit}) {
			commit('setErathems', require('../data/filter.json').erathem);
		},
		getAllMedia({commit}) {
			commit('setAllMedia', require('../data/media.json'));
		},
		async getPeriods({commit}) {
			return await __fetch('periods', commit, 'setPeriods');
		},
		async getTaxa({commit}) {
			return await __fetch('taxa', commit, 'setTaxa');
		}
	}
});
