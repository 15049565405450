export const filterStore = {
	state() {
		return {
			filters: []
		};
	},
	getters: {
		hasFilters(state) {
			return state.filters.length;
		}
	},
	mutations: {
		setFilter(state, data) {
			const
				{filters} = state,
				index = filters.findIndex(({key}) => key === data.key)
			;

			if (!data.value) {
				// Remove empty filter
				if (index !== -1) {
					filters.splice(index, 1);
				}
			} else {
				// Otherwise add or update filter
				(index === -1) ? filters.push(data) : filters.splice(index, 1, data);
			}
		},
		setFilters(state, data = []) {
			state.filters = data;
		}
	},
	actions: {
		addFilter({commit, getters, rootState}, data) {
			const
				{type} = data,
				value = (data.value !== rootState[type]) ? data.value : ''
			;

			switch (type) {
				case 'diet':
					commit('setFilter', {
						key: 'diet',
						field: 'diet',
						value
					});
					commit('setDiet', value);
					break;
				case 'period':
					var period = (typeof value === 'string') ? getters.getPeriodByName(value) : value;
					commit('setFilter', {
						key: 'period',
						field: 'period',
						value: period.name
					});
					commit('setPeriod', period);
					break;
				case 'taxon':
					commit('setFilter', {
						key: 'taxon',
						field: 'taxon',
						value
					});
					commit('setTaxon', value);
					break;
				case 'media':
					commit('setFilter', {
						key: 'media',
						field: 'name',
						value: (value) ?
							rootState.allMedia.find(({name, year}) => value === `${name} (${year})`).animals : ''
					});
					commit('setMedia', value);
					break;
				case 'erathem':
					commit('setErathem', data.value);
					break;
				case 'search':
					commit('setFilter', {
						key: 'search',
						field: ['name', 'alias'],
						operator: 'contains',
						value: data.value.trim()
					});
					commit('setSearch', data.value.trim());
					break;
			}

			// Update active erathem
			if (getters.animals.length && getters.getAnimalsByErathem().length === 0) {
				commit('setErathem', getters.getActiveErathem);
			}
		},
		resetFilters({commit}) {
			commit('setFilters');
			commit('setDiet');
			commit('setMedia');
			commit('setPeriod');
			commit('setSearch');
			commit('setTaxon');
		}
	}
};
