import {settings} from '../../settings';


export const wikipedia = {
	state: {
		content: ''
	},
	mutations: {
		setWikipediaPage(state, content = '') {
			state.content = content;
		}
	},
	actions: {
		getWikipediaPage({commit}, p = {}) {
			var
				url = settings.endpoint.wikipeda + '?origin=*',
				params = Object.assign({
					action: 'parse',
					format: 'json',
					disableeditsection: true,
					redirects: true
				}, p)
			;

			Object.keys(params).forEach(function(key) {
				url += `&${key}=${params[key]}`;
			});

			// Reset while loading
			this.state.content = '';

			fetch(url).then((response) => response.json()).then((data) => {
				if (data && data.parse && data.parse.text) {
					const
						content = data.parse.text['*']
							.replace(/href="\//g, 'target="_blank" href="https://de.wikipedia.org/')
					;

					commit('setWikipediaPage', content);
				}
			});
		}
	}
};
